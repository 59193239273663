import Alpine from "alpinejs";
import JSONPath from "jsonpath";
import he from "he";
import "flowbite";

// Components
import Datepicker from "flowbite-datepicker/Datepicker";
import cmptClientProfileProgram from "../component/client-profile-program-row.js";
import cmptUpdateProgramServiceManagerModal from "../component/update-program-service-manager-modal.js";
import cmptClientDocumentUploadModal from "../component/client-document-upload-modal.js";
import cmptDateSelectInput from "../component/date-select-input.js";
import cmptClientDataFieldUpdateModal from "../component/client-data-field-update-modal.js";
import cmptClientDataPointToolsAction from "../component/client-data-point-tools-select";
import cmptSearch from "../component/search.js";

import fnSoftRemoveDocument from "../scripts/components/documents/SoftRemoveModal";
import fnClientServiceStatus from "../scripts/components/Client/ServiceStatus";
import fnUserIdleStateManager from "../scripts/components/User/IdleStateManager";

/**
 * App Configuration
 *
 * Loading App data from the same source the server App uses to
 * reduce redundancy and ensure consistency when new data is added.
 *
 * This eventually needs to be removed in favor of a better strategy.
 */
import AppConfig from "../../../config/providers.json";

window.env = {
    NODE_ENV: process.env.NODE_ENV
};
window.AppConfig = AppConfig;
window.Alpine = Alpine;

Alpine.store("User", {
    directory: window.AppConfig.Compass.Providers,
    getLabel(name) {
        let provider = name;

        if (this.directory.hasOwnProperty(name)) {
            provider = this.directory[name];
        }

        return provider;
    },
    isAdmin(email) {
        const admin = [
            "miquel@brazilliance.co",
            "tjones@clevelandtaskforce.org",
        ];

        if (admin.includes(email)) {
            return true;
        }

        return false;
    },
    isAuthor(author, userFirstName, userLastName) {
        const authorName = this.getLabel(author);
        const userName = `${userFirstName.charAt(0)}. ${userLastName}`;

        if (authorName === userName) {
            return true;
        }

        return false;
    },
    isReviewer(email) {
        const reviewers = ["vpasalic@clevelandtaskforce.org"];

        if (reviewers.includes(email)) {
            return true;
        }

        return false;
    },
});

Alpine.data("contextInterface", () => ({
    context: null,
    action: null,
    data: {},
    original: null,
    update(uContext = this.context, uAction = this.action, uData = this.data) {
        this.context = uContext;
        this.action = uAction;
        this.data = uData;
        this.original = JSON.stringify(uData);
    },
    getDataValue(path, defaultValue = "&#8212;") {
        let value = JSONPath.query(this.data, `\$.${path}`);
        value = value.length ? value.toString() : defaultValue;
        return he.decode(value);
    },
}));

Alpine.data("updatableSelect", (defaults = []) => ({
    defaults: defaults,
    options: [],
    init() {
        this.inSVL = true;
        this.options = [...defaults].sort();
    },
    reset(options = []) {
        this.inSVL = true;
        this.options = [...defaults, ...options].sort();
    },
    updateOptions(option) {
        if (
            this.$data.action === "edit" &&
            !this.options.includes(option) &&
            option != false &&
            typeof option !== "undefined" &&
            option !== null
        ) {
            if (Array.isArray(option)) {
                this.options = [...option];
            } else {
                this.options.push(option);
                this.inSVL = false;
            }
        }

        this.$nextTick(() => {
            console.log(this.defaults, this.options, option);
        });
    },
    inSVL: true,
    selectedOptionUndetermined(option) {
        if (this.options.includes(option)) {
            return false; // the Option exists in the current Option set..it isn't undetermined
        } else if (
            this.$data.context === "case-note" &&
            this.$data.action === "new"
        ) {
            return true; // no Option has been selected if the Case Note is new
        } else if (
            this.$data.context === "case-note" &&
            this.$data.action === "edit" &&
            (option === "" || typeof option === "undefined" || option === null)
        ) {
            return true; // we are trying to edit the Case Note and the Option has no value
        }

        return null;
    },
}));

Alpine.data("svlNotice", () => ({
    tooltip: null,
    trigger: null,
    target: null,
    init() {
        this.trigger = this.$id("svl-trigger");
        this.target = this.$id("svl-target");

        this.$nextTick(() => {
            const trigger = window.document.getElementById(this.trigger);
            //console.log(this.trigger);
            //console.log(trigger);
            const target = window.document.getElementById(this.target);

            this.tooltip = new Tooltip(target, trigger);
            //console.log(this.tooltip);
        });
    },
}));

Alpine.data("dateSelectComponent", () => ({
    init() {
        const el = this.$el
            .getElementsByTagName("input")
            .namedItem("datepicker");
        new Datepicker(el, {
            autohide: true,
        });
    },
}));

Alpine.data("optionGroup", () => ({
    reset() {
        this.init();
    },
    init() {
        this.data.service_categories = [];
    },
}));

Alpine.data("clientDocumentsTable", () => ({
    removeDocument(e, table) {
        let row = table.querySelector(
            `[data-nc-document-id="${e.detail.Document.id}"]`
        );
        row._x_dataStack[0].remove = true;
    },
}));

Alpine.data(
    "cmptUpdateProgramServiceManagerModal",
    cmptUpdateProgramServiceManagerModal
);

Alpine.data("cmptClientProfileProgram", cmptClientProfileProgram);

Alpine.data("cmptClientDocumentUploadModal", cmptClientDocumentUploadModal);

Alpine.data("cmptDateSelectInput", cmptDateSelectInput);

Alpine.data("cmptClientDataFieldUpdateModal", cmptClientDataFieldUpdateModal);

Alpine.data("cmptClientDataPointToolsAction", cmptClientDataPointToolsAction);

Alpine.data("cmptSearch", cmptSearch);

Alpine.data("fnSoftRemoveDocument", fnSoftRemoveDocument);
Alpine.data("fnClientServiceStatus", fnClientServiceStatus);
Alpine.data("fnUserIdleStateManager", fnUserIdleStateManager);

window.generateUUID = () => {
    // Generate 16 random bytes
    const arr = new Uint8Array(16);
    window.crypto.getRandomValues(arr);

    // Conform to UUID v4 specs, adjusting specific bits for version and variant
    arr[6] = (arr[6] & 0x0f) | 0x40; // Version 4 (random)
    arr[8] = (arr[8] & 0x3f) | 0x80; // Variant 10xx (RFC 4122)

    // Convert to hex string without dashes
    return [...arr].map((b) => b.toString(16).padStart(2, "0")).join("");
}

const originalSetTimeout = window.setTimeout;
const originalSetInterval = window.setInterval;

let activeTimers = {
    timeouts: {},
    intervals: {}
};

window.setTimeout = function (callback, delay, ...args) {
    const timerId = originalSetTimeout(callback, delay, ...args);

	if (args.some(arg => arg.toLowerCase().startsWith("user"))) {
		activeTimers.timeouts[timerId] = { callback, delay, args };
	}

    return timerId;
};

window.setInterval = function (callback, delay, ...args) {
    const timerId = originalSetInterval(callback, delay, ...args);

	if (args.some(arg => arg.toLowerCase().startsWith("user"))) {
		activeTimers.intervals[timerId] = { callback, delay, args };
	}

    return timerId;
};

const originalClearTimeout = window.clearTimeout;
const originalClearInterval = window.clearInterval;

window.clearTimeout = function (timerId) {
    delete activeTimers.timeouts[timerId];
    originalClearTimeout(timerId);
};

window.clearInterval = function (timerId) {
    delete activeTimers.intervals[timerId];
    originalClearInterval(timerId);
};

window.listActiveTimers = function () {
    return activeTimers;
};

(function() {
    const consoleLog = console.log;

    console.log = function(...args) {
		if (window.env && window.env.NODE_ENV === 'development') {
			consoleLog.apply(console, [...args]);
		}
    };
})();

Alpine.start();
